const ru = {
  btn: {
    registration: "Регистрация",
    openAccount: "Открыть аккаунт",
    join: "Присоедениться",
    login: "Войти",
    register: "Зарегистрироваться",
    continue: "Продолжить",
    begin: "Начать торговлю",
    more: "Подробнее",
    confirm: "Подтвердить",
  },
  menu: {
    home: "Главная",
    markets: "Рынки",
    about: "О нас",
    contacts: "Контакты",
    platform: "Платформа",
    faq: "FAQ",
    safe: "Безопасность",
  },
  subMenu: {
    forex: "Forex",
    indices: "Индексы",
    stock: "Акции",
    currency: "Криптовалюты",
    commodities: "Товары",
  },
  form: {
    name: "Имя",
    phone: "Номер телефона",
  },
  privacy: "Политика конфиденциальности",
  terms: "Условия и положения",
  assets: "Активы",
  support: "Техподдержка",
  leverageTo: "Плечо до",
  depositFrom: "Депозит от",
  cities: ["Великобритания", "Кипр", "Гонконг"],
  popularAssets: "Популярные активы",
  worksDay1: "Пн-Пт",
  worksDay2: "Сб-Вс",
  ourAddress: "Наш адрес",
  company: "Компания",
};

export default ru;
