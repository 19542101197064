import axios from "axios";
import { i18n } from "@/main";
import router, { localePath } from "@/router";

const API_URL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  function (config) {
    config.headers["Accept-Language"] = i18n.locale;

    if (config.url.startsWith(API_URL)) {
      config.withCredentials = true;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 403) {
      await router.push(
        localePath({ name: "Login", query: { error: "not_login" } })
      );
    } else {
      return Promise.reject(error);
    }
  }
);

const requestService = {
  get(url, params = {}, headers = {}) {
    return axios.get(API_URL + url, {
      params,
      headers,
    });
  },
  post(url, body = {}, config = {}) {
    return axios.post(API_URL + url, body, config);
  },
  put(url, body = {}, config = {}) {
    return axios.put(API_URL + url, body, config);
  },
  delete(url, config = {}) {
    return axios.delete(API_URL + url, config);
  },
};

export default requestService;
