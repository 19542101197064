import userService from "@/services/user/userService";

export default {
  login: (ctx, user) => {
    return new Promise((resolve, reject) => {
      userService
        .login(user)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  register: (ctx, user) => {
    return userService.register(user);
  },
  forgot: (ctx, email) => {
    return userService.forgot(email);
  },
  recovery: (ctx, { token, password, email, password_confirmation }) => {
    return userService.recovery(token, password, email, password_confirmation);
  },
  activation: (ctx, code) => {
    return userService.activation(code);
  },
  withdraw: (ctx, code) => {
    return userService.withdraw(code);
  },
};
