<template>
  <div class="content">
    <RouterView />
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "EmptyLayout",
  mounted() {
    AOS.init({
      disable: function () {
        var maxWidth = 1200;
        return window.innerWidth < maxWidth;
      },
      duration: 800,
    });
  },
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
</style>
