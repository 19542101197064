<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  position: relative;
}
</style>
<script>
export default {
  name: "App",
  components: {},
};
</script>
