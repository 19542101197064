<script>
export default {
  name: "AppFooter",
  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
  i18n: {
    messages: {
      en: {
        footerText:
          "KTrades is the company registered in the UK and provides services worldwide in accordance with the regulatory requirements of the client's relevant jurisdiction. <br/><i>Risk Warning:</i> leveraged investing carries a high level of risk and may not be suitable for all investors. The information on this website is not intended for distribution to or use by any person or entity in any jurisdiction where such distribution or use may be contrary to any local laws or regulations. Those who access this website do so on their own initiative. The contents of the site do not constitute an invitation to trade.",
      },
      ru: {
        footerText:
          "KTrades компания зарегистрированная в Великобритании, предоставляет услуги по всему миру в соответствии с нормативными требованиями соответствующей юрисдикции клиента. <br/><i>Предупреждение о риске:</i> Инвестирование с использованием кредитного плеча имеет высокую долю заемных средств, несет высокий уровень риска и может подходить не всем инвесторам. Информация на этом веб-сайте не предназначена для распространения или использования любым физическим или юридическим лицом в любой юрисдикции, где такое распространение или использование может противоречить любым местным законам или правилам. Те, кто заходит на этот сайт, делают это по собственной инициативе. Содержимое сайта не является приглашением к торговле.",
      },
      fr: {
        footerText:
          "KTrades est une société enregistrée au Royaume-Uni et fournit des services dans le monde entier conformément aux exigences réglementaires de la juridiction pertinente du client. <br/><i>Avertissement sur les risques:</i> les investissements à effet de levier comportent un niveau de risque élevé et peuvent ne pas convenir à tous les investisseurs. Les informations contenues dans ce site web ne sont pas destinées à être distribuées ou utilisées par une personne ou une entité dans une juridiction où une telle distribution ou utilisation pourrait être contraire aux lois et réglementations locales. Les personnes qui accèdent à ce site le font de leur propre initiative. Le contenu du site ne constitue pas une invitation à la négociation.",
      },
      de: {
        footerText:
          "KTrades ist ein im Vereinigten Königreich eingetragenes Unternehmen, das seine Dienstleistungen weltweit in Übereinstimmung mit den aufsichtsrechtlichen Anforderungen der jeweiligen Gerichtsbarkeit des Kunden erbringt. <br/><i>Risikowarnung:</i> Hebelfinanzierte Anlagen bergen ein hohes Risiko und sind möglicherweise nicht für alle Anleger geeignet. Die Informationen auf dieser Website sind nicht für den Vertrieb oder die Nutzung durch natürliche oder juristische Personen in Ländern bestimmt, in denen ein solcher Vertrieb oder eine solche Nutzung gegen die örtlichen Gesetze oder Vorschriften verstoßen könnte. Wer auf diese Website zugreift, tut dies aus eigenem Antrieb. Der Inhalt der Website stellt keine Aufforderung zum Handel dar.",
      },
    },
  },
};
</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="row row-gap-30">
        <div class="col-md-4 col-xl-2">
          <div class="footer__logo">
            <router-link :to="$localePath({ name: 'Home' })">
              <img src="@/assets/img/logo.svg" alt="" />
            </router-link>
          </div>
          <div class="footer__soc">
            <div class="soc__item">
              <span class="ic-mail soc-item-icon"></span>
              <a href="mailto:support@k-trades.com">Email</a>
            </div>
            <div class="soc__item">
              <span class="ic-send soc-item-icon"></span>
              <a href="tg://resolve?domain=k_trades_bot">Telegram</a>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-xl-4">
          <div class="row row-gap-30">
            <div class="col-6">
              <router-link
                :to="$localePath({ name: 'Markets' })"
                class="footer__title"
              >
                {{ $t("menu.markets") }}
              </router-link>
              <ul class="footer__menu">
                <li>
                  <router-link
                    :to="$localePath({ name: 'Currency' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.currency") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Indices' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.indices") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Stock' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.stock") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Forex' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.forex") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Commodities' })"
                    class="footer__menu-item"
                  >
                    {{ $t("subMenu.commodities") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-6">
              <div class="footer__title">{{ $t("company") }}</div>
              <ul class="footer__menu mb-30">
                <li>
                  <router-link
                    :to="$localePath({ name: 'About' })"
                    class="footer__menu-item"
                  >
                    {{ $t("menu.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :to="$localePath({ name: 'Safety' })"
                    class="footer__menu-item"
                  >
                    {{ $t("menu.safe") }}
                  </router-link>
                </li>
              </ul>
              <div>
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="footer__title"
                >
                  {{ $t("menu.platform") }}
                </router-link>
              </div>
              <router-link
                :to="$localePath({ name: 'Contacts' })"
                class="footer__title"
              >
                {{ $t("menu.contacts") }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="footer__text">
            <p v-html="$t('footerText')"></p>
          </div>
        </div>
      </div>
      <div class="footer__bottom">
        <div class="row row-gap-20 flex-md-row-reverse">
          <div class="col-md-6 text-md-end">
            <div class="bottom__links">
              <router-link :to="$localePath({ name: 'Privacy' })">
                Privacy Policy
              </router-link>
            </div>
          </div>
          <div class="col-md-6">
            <p>KTrades © {{ getFullYear }}, all rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  border-top: 2px solid $blue;
  padding-top: 60px;
}
.footer__logo {
  margin-top: -10px;
  margin-bottom: 20px;
}
.footer__soc {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.soc__item {
  display: flex;
  gap: 10px;
  align-items: center;

  a {
    font-weight: 500;
    font-size: 14px;
    color: $white;
  }
}
.soc-item-icon {
  color: $blue;
  font-size: 18px;
}
.footer__title {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: $blue;
  margin-bottom: 10px;
}
.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer__menu-item {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: $white;
}
.footer__bottom {
  padding: 50px 0;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
}
.bottom__links {
  display: inline-flex;
  align-items: center;
  gap: 50px;

  a {
    color: $white;
  }
}
.footer__text {
  font-weight: 300;
  line-height: 20px;
}

@media (hover: hover) {
  .bottom__links a:hover,
  .soc__item a:hover,
  .footer__menu-item:hover {
    color: $blue;
  }
  a.footer__title:hover {
    color: $white;
  }
}

@include media-breakpoint-down(md) {
  .footer {
    padding-top: 40px;
  }
  .footer__logo {
    margin-top: 0;
  }

  .footer__bottom {
    padding: 35px 0;
  }
}
</style>
