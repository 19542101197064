<template>
  <div class="block__inner">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style scoped></style>
